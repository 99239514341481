export default defineNuxtRouteMiddleware((to) => {
  const token = useOrganizationsAuthStore().currentToken();
  var organization_session =
    persistedState.localStorage.getItem("manager") != null;
  var donor_session = persistedState.localStorage.getItem("donor") != null;
  if (
    to?.name == "2a0c56ec" ||
    to?.name == "b50e46da" ||
    to?.name == "keren-hatorah"
  ) {
    return;
  } else if (
    !token &&
    !["index", "organizations", "donors", "donors-token"].includes(to?.name)
  ) {
    abortNavigation();
    window.localStorage.clear();
    return navigateTo("/");
  } else if (token) {
    if (to?.name == "donors-token") {
      window.localStorage.clear();
    } else if (
      ["index", "organizations", "donors", "donors-token"].includes(to?.name)
    ) {
      if (organization_session) {
        return navigateTo("/organizations/home");
      } else if (donor_session) {
        return navigateTo("/donors/home");
      }
    } else if (
      to.name != null &&
      to?.name.startsWith("organizations-") &&
      !organization_session
    ) {
      return navigateTo("/");
    } else if (
      to.name != null &&
      to?.name.startsWith("donors-") &&
      !donor_session
    ) {
      return navigateTo("/");
    }
  }
});
